<template>

<div class="table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">公告</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
        </el-form>
    </div>
    <div class="content-box">
        <el-collapse accordion v-model="activeName">
            <el-collapse-item v-for="item in tableData" @click.native="handleDetail(item)" :key='item.index' :name="item.id">
                 <template slot="title"><i v-if="item.readState==1" class="header-icon el-icon-message"></i><i v-else-if="item.readState==2" class="header-icon el-icon-success"></i><span style="margin-right:50px;margin-left:50px">{{item.sendTime}}</span>{{item.title}}</template>
                <div>{{item.content}}</div>
            </el-collapse-item>
        </el-collapse>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
import {agentAnnounceList,agentAnnounceRead} from '@/api/systemManagement.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'

export default {
    name:'Announcement',
    mixins:[resize],
    data(){
        return {
            form:{},
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogDetail:false,
            activeName:'',
            currentactiveName:''
        }
    },
    mounted(){
        this.getAgentAnnounceList()
    },
    methods:{
        handleDetail(data){
                this.currentactiveName = data.id
                if(data.readState==1){
                    agentAnnounceRead({id:data.id}).then(res=>{
                       this.getAgentAnnounceList()
                    })
                } 
                
        },
        handleSizeChange(val){
            this.pageSize = val
            this.getAgentAnnounceList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getAgentAnnounceList()
        },
        getAgentAnnounceList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            agentAnnounceList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
                this.activeName = this.currentactiveName
            })
        },
        resetForm(){
        }
    }
}
</script>
